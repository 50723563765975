<template>
  <div ref="echart" class="pie-wrap" :style="{'width': width, 'height': height}"></div>
</template>
<script>
import echarts from '@/plugin/echarts';
export default {
  name: 'charts_pie',
  props: {
    width: { type: String, default: '300px' },
    height: { type: String, default: '200px' },
  },
  data() {
    return {
      echart: null,
    };
  },
  methods: {
    init() {
      this.echart = echarts.init(this.$refs.echart);
      this.echart.setOption({
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
          },
        ],
      });
    },
  },
  mounted() {
    this.$nextTick().then(() => this.init());
  },
};
</script>
<style scoped lang="scss">
</style>
